import './App.css';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { CreateMissingBindingsAsync, DeleteDriverServerBindingAsync, GetDriverServersAsync, GetEmptyDriverAsync, GetEmptyServerAsync, GetFilesAsync, GetServersAsync, PostDriverAsync, PostServerAsync, SendMessageToAllAsync, getServersAsync, getDriversAsync, getUrl, signInAsync, getRoutersAsync, PostSshCommandAsync, GetFileContentAsync, PostFileContentAsync, getDriverIdsWuthServersAsync, getXCheckableServerRouterGroupings, GetIptablesContentAsync, getCodeAsync, checkCodeAsync, ExecSshAsync, CalculatePaymentAsync, ApprovePaymentAsync, GetPaymentHistoryAsync, GetRouterServersHealth, GetServerAWSProperties, GetPaymentHistoryAllAsync, GetTempLinkAsync, GetSharedServerKeysAsync, UpdateAwsStatuses, GetAwsInstanceStatus, SetAwsInstanceStatus } from './api';
import Anser from 'anser';
import pLimit from 'p-limit';
import { act } from 'react';

function App() {
  const [authData, setAuthData] = useState(null);

  const setAsAuthenticated = (authKey) => {
    setAuthData({
      authKey, 
      authDate: new Date()
    });
  };

  const signOut = () => {
    setAuthData(null);
  }

  return (<>
      {authData && <Admin authData={authData} signOut={signOut}/>}
      {!authData && <Auth setAsAuthenticated={setAsAuthenticated}/>}
    </>);
}

function Auth({setAsAuthenticated}){
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [username, setUsername] = useState('');

  const [byPassword, setByPassword] = useState(true);

  const [password, setPassword] = useState('');

  const [code, setCode] = useState('');

  const getCode = async () => {
    setIsSigningIn(true);
    try {
      await getCodeAsync(username);
    } catch (error) {
      alert(error);
    } finally {
      setIsSigningIn(false);
    }
  };

  const signIn = async () => {
    setIsSigningIn(true);
    try {
      let resp;
      if(byPassword){
        resp = await signInAsync(username, password);
      } else {
        resp = await checkCodeAsync(username, code);
      }
      setAsAuthenticated(resp);
    } catch (error) {
      alert(error);
    } finally {
      setIsSigningIn(false);
    }
  };

  const onKeyDown = e => {
    if(e.key === 'Enter' && Boolean(username) && ( (byPassword && Boolean(password)) || (!byPassword && Boolean(code)))){
      signIn();
    }
  };
  
  return <main className="form-signin w-150 m-auto">
      <h1 className="h3 mb-3 fw-normal">Please sign in  
        {byPassword && <button type="button" className="btn btn-primary btn-sm" style={{marginLeft:'10px'}} disabled={isSigningIn} onClick={()=>setByPassword(false)} title='Sign in by code'>CODE</button>}
        {!byPassword && <>
          <button type="button" className="btn btn-primary btn-sm" style={{marginLeft:'10px'}} onClick={()=>setByPassword(true)} disabled={isSigningIn} title='Sign in by password'>PWD</button>
          <button type="button" className="btn btn-primary btn-sm" style={{marginLeft:'10px'}} onClick={async ()=>await getCode()} disabled={!Boolean(username) || isSigningIn} title='Get code'>GET CODE</button>
        </>}
        
      </h1>

      <div className="form-floating mt10">
        <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" value={username} onChange={e=>setUsername(e.target.value)} onKeyDown={onKeyDown}/>
        <label htmlFor="floatingInput">Username</label>
      </div>

      {byPassword && 
        <div className="form-floating mt10">
          <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={e=>setPassword(e.target.value)} onKeyDown={onKeyDown}/>
          <label htmlFor="floatingPassword">Password</label>
        </div>}

      {!byPassword &&<>
        <div className="form-floating mt10">
          <input type="text" className="form-control" id="floatingCode" placeholder="Code" value={code} onChange={e=>setCode(e.target.value)} onKeyDown={onKeyDown}/>
          <label htmlFor="floatingCode">Code</label>
        </div>
      </> }

      <button className="btn btn-primary w-100 py-2 mt10" disabled={isSigningIn || !Boolean(username) || ((byPassword && !Boolean(password) || (!byPassword && !Boolean(code))))} onClick={async () => await signIn()}>Sign in</button>
      <p className="mt-5 mb-3 text-body-secondary mt10">© 2024</p>
  </main>
}



function Admin({authData, signOut}){
  const [page, setPage] = useState("files");
  
  const [focusedDriverForServer, setFocusedDriverForServer] = useState(null);
  
  useEffect(()=>{
    if(Boolean(focusedDriverForServer)){
      setPage("containers");
    }
  }, [focusedDriverForServer]);

  useEffect(()=>{
    if(page !== "containers" && Boolean(focusedDriverForServer)){
      setFocusedDriverForServer(null);
    }
  }, [page]);

  return <div>
    <Header authDate={authData.authDate} signOut={signOut} page={page} setPage={setPage}/>
    {page === "files" && <FilesPage authKey={authData.authKey}/>}
    {page === "drivers" && <Drivers authKey={authData.authKey} status='nonremoved' openServerPageForDriver={driverId => setFocusedDriverForServer(driverId)}/>}
    {page === "driversrm" && <Drivers authKey={authData.authKey} status='removed'/>}
    {page === "message" && <Message authKey={authData.authKey}/>}
    {page === "containers" && <ServersPage authKey={authData.authKey} focusedDriverId={focusedDriverForServer}/>}
    {page === "payments" && <PaymentsPage authKey={authData.authKey}/> }
  </div>
}

function HeaderNavItem({title, currentPage, page, setPage}){
  return <>
    <li className="nav-item" style={{marginRight:'10px'}} title={page}>
      <a className={`nav-link${currentPage === page ? ' active' : ''}`} href="#" onClick={() => setPage(page)}>{title}</a>
    </li>
  </>
}

function Header({authDate, signOut, page, setPage}){
  return <nav className="py-2 bg-body-tertiary border-bottom">
  <div className="container d-flex flex-wrap">
    <ul className="nav me-auto nav-pills">
      <HeaderNavItem title='📂' currentPage={page} page='files' setPage={setPage}/>
      <HeaderNavItem title='🚕' currentPage={page} page='drivers' setPage={setPage}/>
      <HeaderNavItem title='🗑️' currentPage={page} page='driversrm' setPage={setPage}/>
      <HeaderNavItem title='✉️' currentPage={page} page='message' setPage={setPage}/>
      <HeaderNavItem title='📦' currentPage={page} page='containers' setPage={setPage}/>
      <HeaderNavItem title='💵' currentPage={page} page='payments' setPage={setPage}/>
    </ul>
    <ul className="nav">
      <li className="nav-item selfFlexChildVCenter"><TimeLeft authDate={authDate} signOut={signOut}/></li>
      <li className="nav-item"><button href="#" className="nav-link link-body-emphasis px-2" onClick={()=>signOut()}>Sign out</button></li>
    </ul>
  </div>
</nav>
}

function getFirstDayOfCurrentMonth() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = '01';
  
  return `${year}-${month}-${day}`;
}

function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

function PaymentsPage({authKey}){
  const [from, setFrom] = useState(getFirstDayOfCurrentMonth());
  const [to, setTo] = useState(getCurrentDate());
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  

  useEffect(()=>{
    console.log(from, to);
  }, [from, to]);

  const search = async () => {
    try {
      setIsLoading(true);
      setData(null);
      const resp = await GetPaymentHistoryAllAsync(authKey, from, to);
      setData(resp);
    } catch(err){
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  return <div>
    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
      <label htmlFor='filterFrom'>From</label>
      <input id='filterFrom' type='date' style={{margin:'5px'}} value={from} onChange={e=>setFrom(e.target.value)}/>
      <label htmlFor='filterTo' style={{marginLeft:'10px'}}>To</label>
      <input id="filterTo" type='date' style={{margin:'5px', marginRight:'10px'}} value={to} onChange={e=>setTo(e.target.value)}/>
      <button className='btn btn-primary btn-sm' style={{marginRight:'5px'}}  disabled={isLoading} onClick={async ()=> await search()}>Load</button>
    </div>
    {isLoading && <Loading/>}
    
    {data !== null && <div className="table-responsive">
      <table className="table table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => <tr key={item.id}>
            <th scope="row">{index + 1}</th>
            <td>{item.name}</td>
            <td>{item.date}</td>
            <td>${item.amount}</td>
          </tr>)}
          <tr>
            <td colspan="3" style={{textAlign:'end'}}>Total:</td>
            <td scope="row" className="table-active">${data.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)}</td>
          </tr>
        </tbody>
      </table>
    </div>}
  </div>
}

function calculateSecondsBetweenDates(date1, date2) {
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = Math.abs(date1_ms - date2_ms);
  
  // Convert back to seconds and return
  return Math.round(difference_ms / 1000);
}

function secondsToMMSS(seconds) {
  // Calculate minutes and seconds
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  // Pad minutes and seconds if they are less than 10
  minutes = minutes < 10 ? '0' + minutes : minutes;
  remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  // Return the time in MM:ss format
  return minutes + ':' + remainingSeconds;
}

function TimeLeft({authDate, signOut}){
  const [secondsLeft, setSecondsLeft] = useState(3600);

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsPassed = calculateSecondsBetweenDates(authDate, new Date());
      setSecondsLeft(3600 - secondsPassed);
    }, 1000);
    return () => clearInterval(interval);
  }, [authDate]);

  useEffect(()=>{
    if(secondsLeft <= 0){
      signOut();
    }
  }, [secondsLeft]);

  const type = secondsLeft > 900 ? 'success' : (secondsLeft > 300 ? 'warning' : 'danger');
  return <span className={`badge bg-${type}-subtle border border-${type}-subtle text-${type}-emphasis rounded-pill sameWidthFont`} title='Time left before sign out'>{secondsToMMSS(secondsLeft)}</span>
}

function Message({authKey}) {
  const [onlyActive, setOnlyActive] = useState(true);
  const [includeRemoved, setIncludeRemoved] = useState(false);
  const [text, setText] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [serviceFilter, setServiceFilter] = useState(null);

  const send = async () => {
    if(!isSending){
      setIsSending(true);

      try{
        if(window.confirm("Send the message?")){
          const resp = await SendMessageToAllAsync(authKey, {
            onlyActive: onlyActive,
            includeRemoved: includeRemoved,
            text: text,
            serviceFilter: serviceFilter
          });

          setOnlyActive(true);
          setText('');
          alert(resp);
        }
      } catch (error) {
        alert(error);
      } finally {
        setIsSending(false);
      }
    }
  };

  return <div>
    <div style={{margin:'20px', padding:'20px', background: '#efefef', border: '1px solid #e0e0e0', borderRadius: '10px'}}>
      <h3>Broadcast message to customers</h3>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="messageOnlyActive" disabled={isSending} checked={onlyActive} onChange={e=>setOnlyActive(e.target.checked)}/>
        <label className="form-check-label" htmlFor="messageOnlyActive">
          Messsage only active customers
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" id="includeRemoved" disabled={isSending} checked={includeRemoved} onChange={e=>setIncludeRemoved(e.target.checked)}/>
        <label className="form-check-label" htmlFor="includeRemoved">
          Messsage removed customers
        </label>
      </div>
      
      <div className="input-group">
        <span className="input-group-text">Message</span>
        <textarea className="form-control" aria-label="Message" readOnly={isSending} value={text} onChange={e=>setText(e.target.value)}></textarea>
      </div>

      <div style={{background: 'white', borderRadius: '10px', padding: '10px', display: 'flex', marginTop:'10px'}}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="checkboxABC"
            checked={Boolean(serviceFilter)}
            onChange={e=>setServiceFilter(e.target.checked ? {lyft: false, dD: false, uber: false} : null)}
          />
          <label className="form-check-label" htmlFor="checkboxABC">
            Filter
          </label>
        </div>
        
        {Boolean(serviceFilter) && <div style={{display:'flex'}}>
          <div style={{width:'1px', background:'rgb(181 181 181)', marginLeft:'20px', marginRight:'35px'}}></div>
          <div className="form-check mr35" title='Lyft'>
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxA"
              checked={Boolean(serviceFilter?.lyft)}
              onChange={e=>setServiceFilter(f=> ({...f, lyft: e.target.checked}))}
            />
            <label className="form-check-label" htmlFor="checkboxA">
              Lyft
            </label>
          </div>
          <div className="form-check mr35" title='DoorDash'>
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxB"
              checked={Boolean(serviceFilter?.dD)}
              onChange={e=>setServiceFilter(f=> ({...f, dD: e.target.checked}))}
            />
            <label className="form-check-label" htmlFor="checkboxB">
              DoorDash
            </label>
          </div>
          <div className="form-check" title='Uber'>
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxC"
              checked={Boolean(serviceFilter?.uber)}
              onChange={e=>setServiceFilter(f=> ({...f, uber: e.target.checked}))}
            />
            <label className="form-check-label" htmlFor="checkboxC">
              Uber
            </label>
          </div>
        </div>}
      </div>

      {isSending && <Loading/>}

      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <button type="button" className="btn btn-primary mt10" disabled={isSending || !Boolean(text)} onClick={async ()=> await send()}>Send</button>  
      </div>
      
    </div>
  </div>
}

function filterDriver(driver, filterText) {
  if(driver.name.toLowerCase().includes(filterText.toLowerCase())){
    return true;
  }

  return false;
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function Loading(){
  return <div className='selfFlexChildHCenter'>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
}

// function LoadRefresher({status, reload}){
//   if(status === 'loading'){
//     return (
//       <div className='selfFlexChildHCenter' style={{marginTop: '0px', scale: '0.5'}}>
//         <div className="spinner-border text-primary" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </div>
//       </div>
//     );
//   }

//   if(status === "completed"){
//     return  <img src='/refresh-green.png' style={{width: '20px', cursor:'pointer'}} title='Refresh' onClick={()=>reload()}/>
//   }

//   return  <img src='/refresh-red.png' style={{width: '20px', cursor:'pointer'}} title='Failed - Refresh' onClick={()=>reload()}/>
// }

// function ServerBindingCell({driver, serverBindings, onDeleteForDriverId, authKey}){
//   const deleteBinding = async () => {
//     if(window.confirm("Delete server binding?") === true){
//       try {
//         await DeleteDriverServerBindingAsync(authKey, driver.id);
//         alert("Server binding was removed");
//         onDeleteForDriverId(driver.id);
//       } catch (error){
//         alert(error);
//       }
//     }
//   };

//   if(Boolean(serverBindings) === false) return <></>;
//   const binding = serverBindings[driver.id];
//   if(Boolean(binding) === false) return <>❗</>;

//   return <>{binding.server.nameSuffix} <button style={{padding: '0', background: 'none', border: 'none'}} onClick={()=>deleteBinding()}>✖️</button></>
// }

function Drivers({authKey, status, openServerPageForDriver}){
  const [drivers, setDrivers] = useState(null);
  const [driveridswithservers, setDriveridswithservers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [filter, setFilter] = useState('');
  const [focusedService, setFocusedService] = useState(null);
  const [focusedDriver, setFocusedDriver] = useState(null);
  const [emptyDriver, setEmptyDriver] = useState(null);
  const [driverPayments, setDriverPayments] = useState(null);
  const [sharedServerKeys, setSharedServerkeys] = useState(null);

  // const [serverBindingsStatus, setServerBindingsStatus] = useState('completed');
  // const [serverBindings, setServerBindings] = useState(null);
  // const [serverBindingsLoadCounter, setServerBindingsLoadCounter] = useState(0);

  // useEffect(()=> {
  //   if(serverBindingsStatus === "loading") return;
  //   setServerBindingsStatus("loading");
  //   setServerBindings(null);
  //   (async ()=>{
  //     try {
  //       const servers = await GetServersAsync(authKey);
  //       const driverServers = await GetDriverServersAsync(authKey);

  //       const result = {};

  //       for(const driverServer of driverServers){
  //         result[driverServer.driverId] = {
  //           driverServer: driverServer,
  //           server: servers.find(x=>x.id === driverServer.serverId)
  //         };
  //       }
  //       console.log(result);
  //       setServerBindings(result);
  //       setServerBindingsStatus("completed");
  //     } catch {
  //       setServerBindingsStatus("failed");
  //     }
  //   })();
  // }, [serverBindingsLoadCounter]);

  useEffect(() => {
    (async () => {
      const sharedServerKeys = await GetSharedServerKeysAsync(authKey);
      const emptyDriverResp = await GetEmptyDriverAsync(authKey);
      const freshDrivers = await getDriversAsync(authKey, status);
      const driveridswithservers = await getDriverIdsWuthServersAsync(authKey);
      setSharedServerkeys(sharedServerKeys);
      setEmptyDriver(emptyDriverResp)
      setDrivers(freshDrivers);
      setDriveridswithservers(driveridswithservers);
    })();
  },[authKey]);

  useEffect(()=>{
    let targetDrivers = drivers ?? [];
    if(filter?.length){
      targetDrivers = targetDrivers.filter(d => filterDriver(d, filter));
    }

    if (focusedService === 'lyft') {
      targetDrivers = targetDrivers.filter(d => Boolean(d.lyft.email));
    } else if(focusedService === 'doorDash') {
      targetDrivers = targetDrivers.filter(d => Boolean(d.doorDash.email));
    } else if(focusedService === 'uber') {
      targetDrivers = targetDrivers.filter(d => Boolean(d.uber.email));
    } 

    setFilteredDrivers(targetDrivers);
  }, [drivers, filter, focusedService]);

  const onSave = (driver, isNew) => {
    setFocusedDriver(null);
    let driversToSet;
    if(isNew){
      driversToSet = [driver, ...drivers];
    } else {
      driversToSet = drivers.map(x=> x.id === driver.id ? driver : x);
    }

    if((driver.isRemoved === true && status !== "removed") || (driver.isRemoved !== true && status === "removed")){
      driversToSet = driversToSet.filter(c=>c.id !== driver.id);
    }
    
    setDrivers(driversToSet);
  };

  const openServersPage = (driver) => {
    openServerPageForDriver(driver.id);
  };

  // const onDeleteBindingForDriverId = (driverId) => {
  //   if(serverBindings){
  //     const copy = {...serverBindings};
  //     delete copy[driverId];
  //     setServerBindings(copy);
  //   }
  // };

  // const [isGeneratingBindings, setIsGeneratingBindings] = useState(false);

  // const generateBindings = async () => {
  //   if(isGeneratingBindings) return;
  //   try {
  //     setIsGeneratingBindings(true);
  //     const resp = await CreateMissingBindingsAsync(authKey);
  //     setServerBindingsLoadCounter(serverBindingsLoadCounter + 1);
  //     alert(resp);
  //   } catch(err){
  //     alert(err);
  //   } finally {
  //     setIsGeneratingBindings(false);
  //   }
  // };

  return <>
    <header className="py-3 mb-3 border-bottom">
      <div className="container-fluid d-grid gap-3 align-items-center">
        <div className="d-flex align-items-center">
          <button type="button" className="btn btn-primary mr10" disabled={emptyDriver === null} onClick={()=>setFocusedDriver(deepCopy(emptyDriver))}>Add</button>
          <form className="w-100 me-3" role="search">
            <input type="search" className="form-control" placeholder="Search..." aria-label="Search" value={filter} onChange={e=>setFilter(e.target.value)}/>
          </form>
        </div>
      </div>
    </header>

    {drivers === null && <Loading/>}
    
    {drivers !== null && <div className='table-responsive'>
      {/* <button type="button" className="btn btn-primary mr10 btn-sm" style={{marginLeft:'10px'}} disabled={isGeneratingBindings} onClick={()=>generateBindings()}>Create missing driver to server bindings</button> */}
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Expire</th>
            <th>QR</th>
            <th>LTC</th>
            <th>Lyft <input className="form-check-input ml5" type="checkbox" checked={focusedService === 'lyft'} onClick={()=>setFocusedService(f => f === 'lyft' ? null : 'lyft')} readOnly={true}/></th>
            <th>DD<input className="form-check-input ml5" type="checkbox" checked={focusedService === 'doorDash'} onClick={()=>setFocusedService(f => f === 'doorDash' ? null : 'doorDash')} readOnly={true}/></th>
            <th>Uber<input className="form-check-input ml5" type="checkbox" checked={focusedService === 'uber'} onClick={()=>setFocusedService(f => f === 'uber' ? null : 'uber')} readOnly={true}/></th>
            {/* <th style={{display:'flex', alignItems: 'center'}}>Server <LoadRefresher status={serverBindingsStatus} reload={() => setServerBindingsLoadCounter(serverBindingsLoadCounter + 1)}/></th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredDrivers.map((driver, index) => { 
            const hasQr = driveridswithservers.some(c=>c === driver.id);
            const hasCustomQr = driver.sharedServer !== null;
            return <tr key={driver.id} className={!driver.preferences.vpnEnabled || !Boolean(driver.walletAddress) ? 'redTd' : ''}>
            <th>{index + 1}</th>
            <th>{driver.name}</th>
            <td>{driver.phone}</td>
            <td><PaidUntil date={driver.paidUntil}/></td>
            <td><Qr objectId={driver.id} objectType='driver' authKey={authKey} hasQr={hasQr} hasCustomQr={hasCustomQr}/></td>
            <td><LtcQr objectId={driver.id} authKey={authKey} hasQr={Boolean(driver.walletAddress)}/></td>
            <td><input className="form-check-input" type="checkbox" checked={Boolean(driver.lyft.email)} readOnly={true}/></td>
            <td><input className="form-check-input" type="checkbox" checked={Boolean(driver.doorDash.email)} readOnly={true}/></td>
            <td><input className="form-check-input" type="checkbox" checked={Boolean(driver.uber.email)} readOnly={true}/></td>
            {/* <td><ServerBindingCell driver={driver} serverBindings={serverBindings} onDeleteForDriverId={onDeleteBindingForDriverId} authKey={authKey}/></td> */}
            <td style={{position:'relative'}}>
              <button type="button" className="btn btn-info btn-sm" onClick={()=>setFocusedDriver(deepCopy(driver))} style={{padding:'0px 6px'}}>Info</button>
              {Boolean(driver.comment) && <label style={{position: 'absolute', left: '2px', top: '0'}} title={driver.comment}>💬</label>} 
              <button title='Payments' style={{border: 'none', background: 'transparent', fontSize: '15px', marginLeft: '8px'}} onClick={()=>setDriverPayments(driver)}>💰</button>
              {hasQr && Boolean(openServerPageForDriver) && <button title='Open server' style={{border: 'none', background: 'transparent', fontSize: '15px', marginLeft: '8px'}} onClick={()=>openServersPage(driver)}>📦</button>}
            </td>
          </tr>})}
        </tbody>
      </table>
    </div>}

    {focusedDriver && <DriverDetails focusedDriver={focusedDriver} onCancel={() => setFocusedDriver(null)} onSave={onSave} authKey={authKey} sharedServerKeys={sharedServerKeys}/>}
    {driverPayments && <DriverPaymentsWindow authKey={authKey} driver={driverPayments} onCancel={(driver)=> {
      if(driver){
        onSave(driver, false);
      }
      setDriverPayments(null);
    }}/>}
  </>
}

function DriverPaymentsWindow({authKey, driver, onCancel}){
  const [updatedDriver, setUpdatedDriver] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationResult, setCalculationResult] = useState(null);
  const [isShowingHistory, setIsShowingHistory] = useState(false);

  const [payAmount, setPayAmount] = useState(0);

  const [history, setHistory] = useState(null);


  useEffect(()=>{
    if(isShowingHistory){
      setHistory(null);

      (async () => {
        setHistory(await GetPaymentHistoryAsync(authKey, driver.id));
      })();
    }
  },[isShowingHistory]);

  const calculate = async () => {
    if(payAmount > 0){

      try {
        setIsCalculating(true);
        const resp = await CalculatePaymentAsync(authKey, driver.id, payAmount);
        setCalculationResult(resp);
        console.log(resp);
      } catch (error) {
        alert(error);
      } finally {
        setIsCalculating(false);
      }
    } else {
      alert(`Pay amount must be positive number and not ${payAmount}`);
    }
  };

  const approve = async () => {
    try {
      setIsSaving(true);
      const resp = await ApprovePaymentAsync(authKey, calculationResult.id);
      console.log(resp);
      onCancel(resp);
    } catch (error) {
      alert(error);
      setIsSaving(false);
    }
  };

  

  return <div className="modal" tabIndex="-1" style={{display:'block', backgroundColor:'#0000003d'}}>
  <div className="modal-dialog  modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{driver.name}</h5>
        <button type="button" disabled={isSaving} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>onCancel(updatedDriver)}></button>
      </div>
      <div className="modal-body">
        {!isShowingHistory && <>
          <div style={{display:'flex'}}>
            <Input id="paymentAmount" disabled={isCalculating || isSaving} title="Pay Amount" type="number" value={payAmount} onChange={e => setPayAmount(e)} style={{flexGrow:'1'}}/>
            <button disabled={isCalculating || isSaving} onClick={async ()=> await calculate()} title='Calculate' style={{border: 'none', background: 'transparent', fontSize: '25px', marginLeft: '8px', marginTop:'8px'}}>🔢</button>
          </div>
          {isCalculating &&  <Loading/>}
          {!isCalculating && calculationResult && <>
            <div style={{maxWidth: '600px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif'}}>
              <div style={{backgroundColor: '#f5f5f5', borderRadius: '8px', padding: '20px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>Amount:</span>
                  <span style={{color: '#333'}}>{calculationResult.amount} $</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>Service Fee:</span>
                  <span style={{color: '#333'}}>{calculationResult.serviceFee} $</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>Days Extended:</span>
                  <span style={{color: '#333'}}>{calculationResult.daysExtended}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>Previous Expiration Date:</span>
                  <span style={{color: '#333'}}>{calculationResult.previousExpirationDate}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                  <span style={{fontWeight: 'bold'}}>New Expiration Date:</span>
                  <span style={{color: '#333'}}>{calculationResult.newExpirationDate}</span>
                </div>
              </div>

              <button
                disabled={isSaving}
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px',
                  transform: 'rotate(-5deg)',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  padding: '8px 16px',
                  border: 'none',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  boxShadow: isSaving ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.2)', // Remove shadow for disabled state
                  opacity: isSaving ? 0.6 : 1 
                }}
                onClick={async ()=> await approve()}
              >
                Approve
              </button>
            </div>
          </>}
        </>}

        {isShowingHistory && <>
          {history === null && <Loading/>}
          {history !== null && history.length === 0 && <p>No payments yet</p>}
          {history !== null && history.length > 0 && <>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
  {history.map((item, index) => (
    <div
      key={item.id}
      style={{
        flex: '1 1 300px',
        margin: '5px',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', // Add position relative to the card
      }}
    >
      <span
        style={{
          position: 'absolute', // Position the index absolutely
          top: '10px', // Adjust top position
          right: '10px', // Adjust right position
          backgroundColor: '#f0f0f0', // Set a background color for the index
          padding: '5px 10px', // Add some padding
          borderRadius: '5px', // Add rounded corners
        }}
      >
        {index + 1}
      </span>
      <p>Date: {item.date}</p>
      <p>Amount: ${item.amount}</p>
      <p>Service Fee: ${item.serviceFee}</p>
      <p>Days Extended: {item.daysExtended}</p>
      <p>Previous Expiration Date: {item.previousExpirationDate}</p>
      <p>New Expiration Date: {item.newExpirationDate}</p>
    </div>
  ))}
</div>
          </>}
        </>}
      </div>
      <div className="modal-footer">
        <button disabled={isSaving} type="button" className={`btn btn-warning`} onClick={()=> setIsShowingHistory(h=>!h)}>{isShowingHistory ? 'Payment' : 'History'}</button>
        <div style={{flexGrow: '1'}}></div>
        <button disabled={isSaving} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>onCancel(updatedDriver)}>Close</button>
      </div>
    </div>
  </div>
</div>
}

function getDaysBetweenDates(startDate, endDate) {
  // Convert both dates to milliseconds
  var startDate_ms = startDate.getTime();
  var endDate_ms = endDate.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = startDate_ms - endDate_ms;
  
  // Convert back to days and return
  return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

function PaidUntil({date}){
  const diffDays = getDaysBetweenDates(new Date(date), new Date());
  const type = diffDays < 0 ? 'danger' : (diffDays < 5 ? 'warning' : 'success');
  return <span className={`badge bg-${type}-subtle border border-${type}-subtle text-${type}-emphasis rounded-pill sameWidthFont`} title={`Days diff: ${diffDays}`}>{date}</span>
}

function Input({id, title, placeholder, type, value, onChange, style, disabled = false}){
  switch (type) {
    case "checkbox":
      return <>
        <div className="form-check mt10" style={style}>
          <input className="form-check-input" type="checkbox" checked={value} onChange={e=>onChange(e.target.checked)} id={id} readOnly={disabled}/>
          <label className="form-check-label" htmlFor={id}>
            {title}
          </label>
        </div>
      </>
    case "textarea":
      return <>
        <div className="form-group mt10" style={style}>
          <label htmlFor={id}>{title}</label>
          <textarea className="form-control" id={id} rows="3" value={value} onChange={e=>onChange(e.target.value)} readOnly={disabled}></textarea>
        </div>
      </>
    default:
      return <>
        <div className="form-floating mt10" style={style}>
          <input type={type} className="form-control" id={id} placeholder={placeholder} value={value} onChange={e=>onChange(e.target.value)} readOnly={disabled}/>
          <label htmlFor={id}>{title}</label>
        </div>
      </>
  }
}

function ComponentsGroup({children, title}){
  return <>
    <fieldset className="form-group border p-3 mt10">
      <legend className="w-auto px-2" style={{all:'initial', color: '#6f7173'}}>{title}</legend>
      {children}
    </fieldset>
  </>
}

function DriverDetails({focusedDriver, onCancel, onSave, authKey, sharedServerKeys}){
  const [isSaving, setIsSaving] = useState(false);
  const [driver, setDriver] = useState(focusedDriver);
  const isNew = !Boolean(driver.id);

  const isRemoved = driver.isRemoved === true;

  const saveAsync = async () => {
    try {
      setIsSaving(true);
      const resp = await PostDriverAsync(authKey, driver);
      onSave(resp, isNew);
    } catch (error) {
      alert(error);
    } finally {
      setIsSaving(false);
    }
  };

  const toggleStatus = async () => {
    if(window.confirm(`Are you sure you want to ${isRemoved ? 'restore' : 'remove'} driver ${driver.name}`) !== true){
      return;
    }

    try {
      setIsSaving(true);
      const resp = await PostDriverAsync(authKey, {...driver, isRemoved: isRemoved ? false : true});
      onSave(resp, isNew);
    } catch (error) {
      alert(error);
    } finally {
      setIsSaving(false);
    }
  };

  return <div className="modal" tabIndex="-1" style={{display:'block', backgroundColor:'#0000003d'}}>
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{isNew ? 'Add a new driver' : driver.name}</h5>
        <button type="button" disabled={isSaving} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>onCancel()}></button>
      </div>
      <div className="modal-body">
        <Input id="name" title="Name" placeholder="John Doe" type="text" value={driver.name} onChange={e => setDriver({...driver, name:e})}/>
        <Input id="phone" title="Phone" type="text" value={driver.phone} onChange={e => setDriver({...driver, phone:e})}/>
        <Input id="catapush" title="Catapush" type="text" value={driver.catapushId} onChange={e => setDriver({...driver, catapushId:e})}/>
        <Input id="isActive" title="Active" type="checkbox" value={driver.isActive} onChange={e => setDriver({...driver, isActive:e})}/>
        <Input id="paidUntil" title="Paid until" type="date" value={driver.paidUntil} onChange={e => setDriver({...driver, paidUntil:e})}/>
        <Input id="payAmount" title="Pay Amount" type="number" value={driver.payAmount} onChange={e => setDriver({...driver, payAmount:e})}/>
        <Input id="canViewProfile" title="Can View Profile" type="checkbox" value={driver.preferences.canViewProfile} onChange={e => setDriver({...driver, preferences:{...driver.preferences, canViewProfile: e}})}/>
        <Input id="vpnEnabled" title="VPN enabled" type="checkbox" value={driver.preferences.vpnEnabled} onChange={e => setDriver({...driver, preferences:{...driver.preferences, vpnEnabled: e}})}/>
        <Input id="comment" title="Comment" placeholder="..." type="textarea" value={driver.comment} onChange={e => setDriver({...driver, comment:e})}/>
        <Input id="walletAddress" title="Wallet Address" placeholder="..." type="text" value={driver.walletAddress} onChange={e => setDriver({...driver, walletAddress:e})}/>

        <ComponentsGroup title="Shared server">
          <select className="form-select" title='Driver' value={driver.sharedServer} onChange={e=>setDriver({...driver, sharedServer: e.target.value === "none" ? null : e.target.value})}>
            <option value={undefined}>none</option>
            {sharedServerKeys.map(x=><option key={x} value={x}>{x}</option>)}
          </select>
        </ComponentsGroup>

        <ComponentsGroup title="lyft">
          <Input id="lyft-email" title="Email" placeholder="JohnDoe@mail.com" type="text" value={driver.lyft.email} onChange={e => setDriver({...driver, lyft: {...driver.lyft, email: e}})}/>
          <Input id="lyft-minOfferPrice" title="Min Offer Price" placeholder="20" type="number" value={driver.lyft.minOfferPrice} onChange={e => setDriver({...driver, lyft: {...driver.lyft, minOfferPrice: e}})}/>
          <Input id="lyft-minRideDistanceMi" title="Min Ride Distance Mi" placeholder="0" type="number" value={driver.lyft.minRideDistanceMi} onChange={e => setDriver({...driver, lyft: {...driver.lyft, minRideDistanceMi: e}})}/>

          <ComponentsGroup title="Services">
            <Input id="lyft-services-booster" title="Booster" type="checkbox" value={driver.lyft.services.booster} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, booster: e}}})}/>
            <Input id="lyft-services-pickupDropoff" title="PickupDropoff" type="checkbox" value={driver.lyft.services.pickupDropoff} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, pickupDropoff: e}}})}/>
            <Input id="lyft-services-autoJobAccept" title="AutoJobAccept" type="checkbox" value={driver.lyft.services.autoJobAccept} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, autoJobAccept: e}}})}/>
            <Input id="lyft-services-scanner" title="Scanner" type="checkbox" value={driver.lyft.services.scanner} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, scanner: e}}})}/>
            <Input id="lyft-services-autorequestride" title="AutoRequestRide" type="checkbox" value={driver.lyft.services.autorequestRide} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, autorequestRide: e}}})}/>
            <Input id="lyft-services-bot" title="Bot" type="checkbox" value={driver.lyft.services.bot} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, bot: e}}})}/>
            <Input id="lyft-services-minVersionBypass" title="MinVersionBypass" type="checkbox" value={driver.lyft.services.minVersionBypass} onChange={e => setDriver({...driver, lyft: {...driver.lyft, services: {...driver.lyft.services, minVersionBypass: e}}})}/>
          </ComponentsGroup>
        </ComponentsGroup>
        <ComponentsGroup title="dd">
          <Input id="dd-email" title="Email" placeholder="JohnDoe@mail.com" type="text" value={driver.doorDash.email} onChange={e => setDriver({...driver, doorDash: {...driver.doorDash, email: e}})}/>

          <ComponentsGroup title="Services">
            <Input id="dd-services-booster" title="Booster" type="checkbox" value={driver.doorDash.services.booster} onChange={e => setDriver({...driver, doorDash: {...driver.doorDash, services: {...driver.doorDash.services, booster: e}}})}/>
            <Input id="dd-services-dashnow" title="Dashnow" type="checkbox" value={driver.doorDash.services.dashnow} onChange={e => setDriver({...driver, doorDash: {...driver.doorDash, services: {...driver.doorDash.services, dashnow: e}}})}/>
          </ComponentsGroup>
        </ComponentsGroup>
        <ComponentsGroup title="uber">
          <Input id="uber-email" title="Email" placeholder="JohnDoe@mail.com" type="text" value={driver.uber.email} onChange={e => setDriver({...driver, uber: {...driver.uber, email: e}})}/>

          <ComponentsGroup title="Services">
            <Input id="uber-services-booster" title="Booster" type="checkbox" value={driver.uber.services.booster} onChange={e => setDriver({...driver, uber: {...driver.uber, services: {...driver.uber.services, booster: e}}})}/>
          </ComponentsGroup>
        </ComponentsGroup>
      </div>
      <div className="modal-footer">
      <button disabled={isSaving} type="button" className={`btn btn-${isRemoved ? 'success' : 'danger'}`} onClick={async ()=> await toggleStatus()}>{isRemoved ? 'Restore' : 'Remove'}</button>
        <button disabled={isSaving} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>onCancel()}>Close</button>
        <button disabled={isSaving} type="button" className="btn btn-primary" onClick={async ()=>await saveAsync()}>{isNew ? 'Add' : 'Update'}</button>
      </div>
    </div>
  </div>
</div>
}

function FileItem({fileInfo, authKey}){
  const [isCreatingTempLink, setIsCreatingTempLink] = useState(false);
  const onCopyTempLinkClick = async (e) => {
    e.preventDefault();
    if(!isCreatingTempLink){
      setIsCreatingTempLink(true);

      try {
        const link = await GetTempLinkAsync(authKey, fileInfo.key, fileInfo.name);
        console.log(link);
        prompt(`Temp link for file ${fileInfo.name}`, link)
      } catch (error) {
        alert(error);
      } finally {
        setIsCreatingTempLink(false);
      }
    }
  };
  return <>
    <a href={getUrl(`/file/${Date.now()}/${fileInfo.key}/${fileInfo.name}?key=${authKey}`)} className="fileItem" download={true}> 
      <img src='/file-earmark-arrow-down.svg'/> 
      <label>{fileInfo.name} </label>
      <button onClick={e => onCopyTempLinkClick(e)} className={isCreatingTempLink ? 'rotationClass' : ''}>{isCreatingTempLink ? '⏳' : '🔗'}</button></a>
  </>
}

function Accordion({directoryInfo, authKey}){
  const [isOpen, setIsOpen] = useState(false);
  const isRoot = directoryInfo.name === '.';
  
  return <div className={!isRoot ? 'accordionContainer' : ''}>
    { !isRoot && <button className={`accordion${isOpen ? ' active-item' : ''}`} onClick={()=>setIsOpen(!isOpen)}>{directoryInfo.name}</button>}
    {(isOpen || isRoot) && <div className="panel">
      {directoryInfo.directories.map(x=><Accordion key={x.path} directoryInfo={x} authKey={authKey}/>)}
      {directoryInfo.files.length && <div className='fileItemsContainer'>
          {directoryInfo.files.map(x=><FileItem key={x.key} fileInfo={x} authKey={authKey}/>)}
        </div>}
    </div>}
  </div>
}

function FilesPage({authKey}){
  const [directoryInfo, setDirectoryInfo] = useState(null);

  useEffect(()=>{
    (async ()=>{
      try {
        const resp = await GetFilesAsync(authKey);
        setDirectoryInfo(resp);
      } catch (error) {
        alert(error);
      }
    })();
  }, []);
  return <div style={{marginTop:'10px'}}>
    {directoryInfo === null && <Loading/>}
    {directoryInfo && <Accordion directoryInfo={directoryInfo} authKey={authKey}/>}
  </div>
}

const sshCredentials = ["Ubuntu", "AL2023"];

function ServersPage({authKey, focusedDriverId}){
  const [routers, setRouters] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [emptyServer, setEmptyServer] = useState(null);
  const [servers, setServers] = useState(null);
  const [pickedRouterId, setPickedRouterId] = useState(null);

  const [loading, setLoading] = useState(true);

  const [focusedServer, setFocusedServer] = useState(null);

  const [isViewingCommander, setIsViewingCommander] = useState(false);

  const scrollToRef = useRef(null);
  const scrolledToRef = useRef(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const routersResp = await getRoutersAsync(authKey);
        const driversResp = await getDriversAsync(authKey, "nonremoved");
        const emptyDriverConfigResp = await GetEmptyServerAsync(authKey);
        const freshDriverConfigs = await getServersAsync(authKey);
        setRouters(routersResp);
        setDrivers(driversResp);
        setEmptyServer(emptyDriverConfigResp)
        setServers(freshDriverConfigs);
      } catch (error) {
        alert(error);
      } finally {
        setLoading(false);
      }
    })();
  },[authKey]);

  const scrollToRefFunc = () => {
    scrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  useEffect(()=>{
    if (scrollToRef.current && !scrolledToRef.current) {
      scrollToRefFunc();
      scrolledToRef.current = true;
    }
  },[servers]);

  if(loading){
    return <Loading/>
  }

  if(isViewingCommander){
    return <Commander authKey={authKey} exitCommander={()=>setIsViewingCommander(false)} servers={servers} routers={routers}/>
  }


  const getDriverName = (driverId) => {
    const driver = drivers.find(x=>x.id === driverId);
    if(driver){
      return driver.name;
    }
    return "-";
  };

  const getRouterName = (driverId) => {
    const router = routers.find(x=>x.id === driverId);
    if(router){
      return router.name;
    }
    return "-";
  };

  const onSave = (driverConfig, isNew) => {
    setFocusedServer(null);
    let driverConfigsToSet;
    if(isNew){
      driverConfigsToSet = [driverConfig, ...servers];
    } else {
      driverConfigsToSet = servers.map(x=> x.id === driverConfig.id ? driverConfig : x);
    }
    setServers(driverConfigsToSet);
  };

  const getDriversForPicker = () => {
    const mapped = drivers.map(x=>({id:x.id, name:x.name}))
    return mapped;//todo:filter only available drivers without configs
  };

  const togglePickedRouterId = (routerId) => {
    if(pickedRouterId === routerId){
      setPickedRouterId(null);
    } else {
      setPickedRouterId(routerId);
    }
  };

  let displayServers = []; 
  let emojiCounts = undefined;

  if(servers !== null){
    displayServers = servers.filter(x=>pickedRouterId === null || pickedRouterId === x.routerId);
    emojiCounts = displayServers.map(s=>ServerStatus({server: s})).reduce((acc, obj) => {
      if (acc[obj]) {
        acc[obj] += 1;
      } else {
        acc[obj] = 1;
      }
      return acc;
    }, {});
  }

  return <>
    <div style={{display:'flex', alignItems:'center'}}>
      {routers && routers.map(r=><button key={r.id} style={{margin:'1px'}} type="button" className={`btn btn-${pickedRouterId === r.id ? "success" : "warning"} btn-sm`} onClick={()=>togglePickedRouterId(r.id)}>{r.name}</button>)}
      <div style={{flexGrow:'1'}}></div>
      {Boolean(focusedDriverId) && <button onClick={()=>scrollToRefFunc()} style={{background:'transparent', border:'none'}} title='Scroll to driver'>🚕</button>}
      {emojiCounts && <div className='emojiCount'>
        {
          Object.entries(emojiCounts).map(([status, count]) => (
            <label key={status}>{status} {count}</label>
          ))
        }
      </div>}
    </div>
    {servers !== null && <div className='table-responsive'>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th><button onClick={()=>setIsViewingCommander(true)} style={{background:'transparent', border:'none'}} title='Go to commander'>#</button></th>
            <th>Aws Instance Id</th>
            <th>Ip</th>
            <th>Index</th>
            <th>Driver</th>
            <th>Router</th>
            <th>Status</th>
            <th>SshCreds</th>
            <th>QR</th>
            <th>AWS</th>
            {/* <th>CLI</th> */}
            <th><button type="button" className="btn btn-success btn-sm" readOnly={!Boolean(emptyServer)} onClick={()=>setFocusedServer(deepCopy(emptyServer))} style={{padding:'0px 6px'}}>+ Add</button></th>
          </tr>
        </thead>
        <tbody>
          {displayServers.map((server, index) => {
            const isFocusedServerForDriver = Boolean(focusedDriverId) && server.driverId === focusedDriverId;
            return <tr key={server.id} title={server.id} className={isFocusedServerForDriver ? "focused_server_for_driver" : ""} ref={isFocusedServerForDriver? scrollToRef : null}>
            <th>{index + 1}</th>
            <th>{server.awsInstanceId}</th>
            <td>{server.ip}</td>
            <td>{server.index ?? '-'}</td>
            <td>{getDriverName(server.driverId)}</td>
            <td>{getRouterName(server.routerId)}</td>
            <td><ServerStatus server={server}/></td>
            <td>{server.sshCredentials ?? '-'}</td>
            <td><Qr authKey={authKey} objectId={server.id} objectType='server' hasQr={true}/></td>
            <td><AwsInstanceController authKey={authKey} awsInstanceId={server.awsInstanceId} routerId={server.routerId} onlyStatus={true}/></td>
            {/* <td><button type="button" className="btn btn-info btn-sm" onClick={()=>setFocusedServer(deepCopy(server))} style={{padding:'0px 6px'}}>Info</button></td> */}
            <td style={{position:'relative'}}>
              <button type="button" className="btn btn-info btn-sm" onClick={()=>setFocusedServer(deepCopy(server))} style={{padding:'0px 6px'}}>Info</button>
              {Boolean(server.comment) && <label style={{position: 'absolute', left: '2px', top: '0'}} title={server.comment}>💬</label>} 
            </td>
          </tr>})}
        </tbody>
      </table>
    </div>}

    {focusedServer && <DriverConfigDetails focusedServer={focusedServer} authKey={authKey} onSave={onSave} onCancel={()=>setFocusedServer(null)} drivers={getDriversForPicker()} servers={servers} routers={routers}/>}
  </>
}

function generateUniqueCredentials() {
  // Define character sets for username and password
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  // Function to generate a random string
  function generateRandomString(length, chars) {
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  // Generate unique username
  const username = generateRandomString(12, chars);

  // Generate unique password
  const password = generateRandomString(12, chars);

  return { username, password };
}

function getMaxServerIndex(servers) {
  const validIndexes = servers
    .filter(obj => obj.index !== null && obj.index !== undefined)
    .map(obj => obj.index);

  if (validIndexes.length === 0) {
    return 0;
  }

  return Math.max(...validIndexes);
}

function DriverConfigDetails({focusedServer, onCancel, onSave, authKey, drivers, servers, routers}){
  const [isSaving, setIsSaving] = useState(false);
  const [server, setServer] = useState(focusedServer);
  const isNew = !Boolean(server.id);
  const isInitialRef = useRef(true);
  
  const saveAsync = async () => {
    try {
      setIsSaving(true);
      const resp = await PostServerAsync(authKey, server);
      onSave(resp, isNew);
    } catch (error) {
      alert(error);
    } finally {
      setIsSaving(false);
    }
  };

  const generateUsernamePassword = () => {
    const creds = generateUniqueCredentials();
    setServer({...server, proxyUsername:creds.username, proxyPassword: creds.password});
  };

  useEffect(()=>{
    let altered = {...server};
    let wasAltered = false;
    //if is new set defaults
    if(!Boolean(altered.id)){

      //set default ssh creds
      if(!Boolean(altered.sshCredentials) && isInitialRef.current){
        const found = sshCredentials.find(c=>c === 'AL2023');
        if(found){
          altered = {...altered,sshCredentials: found };
          wasAltered = true;
        }
      }

      //set usr/pwd
      if(!Boolean(altered.proxyUsername) && !Boolean(altered.proxyPassword) && isInitialRef.current){
        const creds = generateUniqueCredentials();
        altered = {...altered, proxyUsername:creds.username, proxyPassword: creds.password};
        wasAltered = true;
      }

      //set dir
      if(!Boolean(altered.mitmProxyDir) && isInitialRef.current){
        altered = {...altered,mitmProxyDir: "/home/ec2-user/mitm/" };
        wasAltered = true;
      }

      //set index
      if(Boolean(altered.routerId) && !Boolean(altered.index)){
        
        const allyServers = servers.filter(c=>c.routerId === altered.routerId);
        const maxIndex = getMaxServerIndex(allyServers);
        
        altered = {...altered, index: maxIndex + 1};
        
        wasAltered = true;
      }
    }

    isInitialRef.current = false;
    if(wasAltered){
      setServer(altered);
    }
  },[server]);

  const autoFillInstanceIdAndIp = async () => {
    try {
      setIsSaving(true);
      const resp = await GetServerAWSProperties(authKey, server.routerId, server.index);
      if(resp){
        setServer(s=>({
          ...s,
          awsInstanceId: resp.awsInstanceId ?? '',
          ip: resp.ip ?? ''
        }));
      }
    } catch (error) {
      alert(error);
    } finally {
      setIsSaving(false);
    }
  };

  return <div className="modal" tabIndex="-1" style={{display:'block', backgroundColor:'#0000003d'}} aria-hidden="true" role="dialog">
  <div className="modal-dialog modal-dialog-scrollable" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{isNew ? 'Add a new config' : "Modify config"}</h5>
        <button type="button" disabled={isSaving} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>onCancel()}></button>
      </div>
      <div className="modal-body">
        <AwsInstanceController authKey={authKey} awsInstanceId={server.awsInstanceId} routerId={server.routerId}/>
        <select className="form-select mt10" title='Router' value={server.routerId} onChange={e=>setServer({...server, routerId: e.target.value === "none" ? null : e.target.value})}>
          <option value={undefined}>none</option>
          {routers.map(x=><option key={x.id} value={x.id}>{x.name}</option>)}
        </select>
        {/* <Input id="wgPrivateKey" title="WgPrivateKey" placeholder="..." type="text" value={server.wgPrivateKey} onChange={e => setServer({...server, wgPrivateKey:e})}/> */}
        {/* <Input id="wgPublicKey" title="WgPublicKey" placeholder="..." type="text" value={server.wgPublicKey} onChange={e => setServer({...server, wgPublicKey:e})}/> */}
        <Input id="ip" title="IP" placeholder="..." type="text" value={server.ip} onChange={e => setServer({...server, ip:e})}/>
        <Input id="index" title="Index" placeholder="..." type="number" value={server.index} onChange={e => setServer({...server, index:e === '' ? null : e})}/>
        <Input id="awsInstanceId" title="AwsInstanceId" placeholder="..." type="text" value={server.awsInstanceId} onChange={e => setServer({...server, awsInstanceId:e})}/>
        <Input id="mitmProxyDir" title="MitmproxyDir" placeholder="..." type="text" value={server.mitmProxyDir} onChange={e => setServer({...server, mitmProxyDir:e})}/>
        <Input id="proxyUsername" title="ProxyUsername" placeholder="..." type="text" value={server.proxyUsername} onChange={e => setServer({...server, proxyUsername:e})}/>
        <Input id="proxyPassword" title="ProxyPassword" placeholder="..." type="text" value={server.proxyPassword} onChange={e => setServer({...server, proxyPassword:e})}/>
        <Input id="comment" title="Comment" placeholder="..." type="textarea" value={server.comment} onChange={e => setServer({...server, comment:e})}/>
        <select className="form-select mt10" title='Driver' value={server.driverId} onChange={e=>setServer({...server, driverId: e.target.value === "none" ? null : e.target.value})}>
          <option value={undefined}>none</option>
          {drivers.filter(c=>c.id === server.driverId || !Boolean(servers.find(sr=>sr.driverId === c.id))).map(x=><option key={x.id} value={x.id}>{x.name}</option>)}
        </select>
        <select className="form-select mt10" title='SSH Credentials' value={server.sshCredentials} onChange={e=>setServer({...server, sshCredentials: e.target.value === "none" ? null : e.target.value})}>
          <option value={undefined}>none</option>
          {sshCredentials.map(x=><option key={x} value={x}>{x}</option>)}
        </select>
      </div>
      <div className="modal-footer">
        <button title='Generate random username and password' disabled={isSaving} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>generateUsernamePassword()}>USR/PWD</button>
        <button title='Autofill IP and InstanceID by index and router' disabled={isSaving || !Boolean(server.routerId) || !Boolean(server.index) || server.index <= 0} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>autoFillInstanceIdAndIp()}>AWS</button>
        <div style={{flexGrow: '1'}}></div>
        <button disabled={isSaving} type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>onCancel()}>Close</button>
        <button disabled={isSaving} type="button" className="btn btn-primary" onClick={async ()=>await saveAsync()}>{isNew ? 'Add' : 'Update'}</button>
      </div>
    </div>
  </div>
</div>
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function Qr({objectId, objectType, authKey, hasQr, hasCustomQr}){
  const [isOpen, setIsOpen] = useState(false);
  ///api/admin/wgqr/65ce3485a801b8b793b1c976/asd?key=05ca1e41-5651-44e1-a9ca-5a0c8b1c410e

  return <>
    <button className={`btn btn-${hasQr ? 'primary' : (hasCustomQr ? 'warning' : 'danger')} btn-sm`} style={{padding:'0px 6px'}} onClick={()=>setIsOpen(hasQr || hasCustomQr)} title={hasQr ? 'Private server' : (hasCustomQr ? 'Shared server' : 'No server')}>QR</button>
    {isOpen && <div style={{ zIndex:'1', background: "black", position: "fixed", left: 0, top: 0, bottom: 0, right: 0, padding: "30px"}}>
      <img src={getUrl(`/wgqr/${objectId}/${objectType}/${getRandomInt(99999)}?key=${authKey}`)} onClick={()=>setIsOpen(false)} style={{objectFit:'scale-down', width:'100%', height: '100%'}}/>
      </div>}
  </>
}

function LtcQr({objectId, authKey, hasQr}){
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <button className={`btn btn-${hasQr ? 'primary' : 'danger'} btn-sm`} style={{padding:'0px 6px'}} onClick={()=>setIsOpen(hasQr)} title={hasQr ? 'LTC Address' : 'No LTC Address'}>LTC</button>
    {isOpen && <div style={{ zIndex:'1', background: "black", position: "fixed", left: 0, top: 0, bottom: 0, right: 0, padding: "30px"}}>
      <img src={getUrl(`/wltqr/${objectId}/${getRandomInt(99999)}?key=${authKey}`)} onClick={()=>setIsOpen(false)} style={{objectFit:'scale-down', width:'100%', height: '100%'}}/>
      </div>}
  </>
}

function ServerStatus({server}){
  if(allStringsValid([server.routerId, server.ip, server.awsInstanceId, server.sshCredentials, server.mitmProxyDir, server.proxyUsername, server.proxyPassword, server.driverId]))
    return '🟢';
  if(allStringsValid([server.routerId, server.ip, server.awsInstanceId, server.sshCredentials, server.mitmProxyDir, server.proxyUsername, server.proxyPassword]))
    return '🟡';
  return '🔴';
}

function allStringsValid(strings) {
  return strings.every(str => {
    if (typeof str === 'string' && str.trim().length > 0) {
      return true;
    }
    return false;
  });
}

function CommanderTabItem({thisPage, currentPage, setCurrentPage}){
  return <li className="nav-item">
    <button className={`nav-link${thisPage === currentPage ? ' active':''}`} aria-current="page" onClick={()=>setCurrentPage(thisPage)}>{thisPage}</button>
  </li>
}

function Commander({authKey, exitCommander, servers, routers}){
  const [page, setPage] = useState('Servers');
  
  return <>
    <ul className="nav nav-tabs mt10" style={{paddingLeft:'5px', paddingRight:'5px'}}>
      <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'Servers'}/>
      {/* <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'Routers'}/> */}
      {/* <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'MitmConf'}/> */}
      <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'iptables'}/>
      <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'Health check'}/>
      <CommanderTabItem currentPage={page} setCurrentPage={setPage} thisPage={'Actions'}/>
      <button onClick={()=>exitCommander()} title='Exit commander' style={{background:'transparent', border:'none', marginLeft:'10px', marginRight:'5px'}}>❌</button>
    </ul>

    {page === "Servers" && <ServersCommander authKey={authKey} servers={servers} routers={routers}/>}
    {page === 'iptables' && <IptablesViewer authKey={authKey} routers={routers}/>}
    {page === 'Health check' && <HealthCheck authKey={authKey} routers={routers}/>}
    {page === 'Actions' && <OneClickCommandsTab authKey={authKey}/>}
  </> 
}

function OneClickCommandsTab({authKey}){
  const [output, setOutput] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);

  const actions = [
    {
      title: "Update AWS instaces states",
      action: async () => {
        const resp = await UpdateAwsStatuses(authKey);
        return resp.logs;
      }
    },
    {
      title: "Clear",
      action: () => ""
    }
  ];
  
  const runAction = async (action) => {
    setOutput(null);
    setCurrentAction(action.title);
    try {
      const resp = await action.action();
      setOutput(resp);
    } catch (error) {
      setOutput(`!!!ERROR!!!\n\n${error}`);
    } finally {
      setCurrentAction(null);
    }
  };

  return <div style={{margin:'20px'}}>
    {actions.map(a => (
      <button key={a.title} className="btn btn-primary" type="button" disabled={Boolean(currentAction)} onClick={()=> runAction(a)} style={{margin: '3px'}}>
        {currentAction === a.title && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight:'5px'}}></span>}
        {a.title}
      </button>
    ))}
    
    {output && <p style={{padding:'30px'}} dangerouslySetInnerHTML={{__html: output.replace(/\n/g, '<br />')}}/>}
  </div>
}

function getXCheckedServersCount(routers){
  let cnt = 0;
  console.log(routers);
  for(let router of routers){
    for(let server of router.servers){
      if(server.checked){
        cnt++;
      }
    }
  }

  return cnt;
}

function HealthCheck({authKey, routers}){
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRouterId, setSelectedRouterId] = useState('');

  const load = async () => {
    try {
      setIsLoading(true);
      setContent(null);
      const resp = await GetRouterServersHealth(authKey, selectedRouterId);
      setContent(resp);
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return <>
  <div className='d-flex justify-content-end'>
    <div style={{flexGrow:'1'}}></div>
    <select style={{width:'150px'}} className="form-select mt10" title='Router' readOnly={isLoading} value={selectedRouterId} onChange={e=>setSelectedRouterId(e.target.value === '' ? '' : e.target.value)}>
      <option value="">All</option>
      {routers.map(x=><option key={x.id} value={x.id}>{x.name}</option>)}
    </select>
    <button className='btn btn-primary btn-sm' style={{padding:'0px 6px', margin: '5px', marginTop:'15px', marginRight:'20px'}} disabled={isLoading || !Boolean(selectedRouterId)} onClick={async ()=> await load()}>Check</button>
  </div>
  <div className="form-group" style={{padding:'20px', paddingTop:'10px'}}>
    {isLoading && <Loading/>}
    {content && <HealthInfo info={content} leve={0}/>}
  </div>
</>
}

function HealthInfo({info, level}){
  const [isOpen, setIsOpen] = useState(false);
  return <div title={info.id} style={{border:'1px solid gray', padding:'5px', borderRadius:'5px', marginTop:'5px'}}>
    <div style={{display:'flex', flexDirection:'row', cursor:'pointer'}} onClick={() => setIsOpen(c=>!c)}>
      {info.subItems && <span>{!isOpen ? '↓ ' : '↑ '}</span>}
      <span> {info.name}</span>
      <span style={{flexGrow:'1'}}></span>
      <span>{info.passed ? '🟢' : '🔴'}</span>
    </div>
    {isOpen && info.subItems && info.subItems.map(subItem => <div key={subItem.id}><HealthInfo info={subItem} level={level + 1}/></div>)}
  </div>
}

function delay(time) {
  return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
  });
}

function ServersCommander({authKey}){
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCommand, setSelectedCommand] = useState('');
  const [isRunning, setIsRunning] = useState(undefined);
  const [focusedServer, setFocusedServer] = useState(null);
  const [liveLogsServer, setLiveLogsServer] = useState(null);
  const [autoscroll, setAutoscroll] = useState(true);
  const [parallelCount, setParallelCount] = useState(6);
  const [collapsedRouters, setCollapsedRouters] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const dataResp = await getXCheckableServerRouterGroupings(authKey);
        setData(dataResp);
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [authKey]);

  const go = async () => {
    setIsRunning(true);
    for(const router of data.routers){
      for(const server of router.servers){
        server.status = server.checked ? '🟡' : '⚫';
      }
    }
    setData({...data});

    const limit = pLimit(parallelCount); 
    let lock = Promise.resolve();

    

    const withLock = async (fn) => {
        const release = lock;
        let releaseNext;
        lock = new Promise(resolve => releaseNext = resolve);
        await release;
        try {
            return await fn();
        } finally {
            releaseNext();
        }
    };

    const promises = data.routers.flatMap(r=>r.servers).filter(s=>s.checked).map((server) => limit(async () => {
      await withLock(() => {
        server.status = '🔵';
        setData({...data})
      }); 
      
      const req = {
        commandId: selectedCommand,
        serverId: server.id
      };
      let resp;
      try {
        resp = await ExecSshAsync(authKey, req);
      } catch (error) {
        resp = {...req, isErrorOutput: true, output: error};
      } 
      
      await withLock(()=>{
        server.status = resp.isErrorOutput ? '🔴' : '🟢';
        server.response = resp;
      
        if(!server.isErrorOutput){
          if(selectedCommand === '6636b79133a1dab8302f97cb'){ //checksum_mitm
            if(resp.output !== ''){
              server.status = '🔴';
            }
          } else if(selectedCommand === '6637c93ac8e4de9146c48c8a'){ //restart_mitmdump
            if(resp.output !== 'mitmdump\n') {
              server.status = '🔴';
            }
          } else if(selectedCommand === '663802bf092760f657493755') { //restart_mitmdump_clean_logs
            if(resp.output !== 'mitmdump\nmitmdump\n') {
              server.status = '🔴';
            }
          } else if(selectedCommand === '66e3e553b939a26088d6b4d8') { //get_status
            if(!resp.output.startsWith('running')) {
              server.status = '🔴';
            }
          }
        }
      
        setData({...data});
      });
    }));

    await Promise.all(promises);

    setIsRunning(false);
  };
  
  if(isLoading){
    return <Loading/>
  }

  const toggleItem = (server, router) => {
    const newServer = {...server, checked: !server.checked};
    const newRouter = {...router, servers: router.servers.map(s=>s.id === newServer.id ? newServer : s)};

    const routers = data.routers.map(r=>r.id === newRouter.id ? newRouter : r);
    
    setData({...data, checkedServersCount: getXCheckedServersCount(routers), routers: routers});
  };

  const setCheckedAll = (router, state) => {
    let routers;
    if(router === undefined){
      routers = data.routers.map(r=> ({...r, servers: r.servers.map(s=>({...s, checked: state}))}));
    } else {
      const newRouter = {...router, servers: router.servers.map(s=>({...s, checked: state}))};

      routers = data.routers.map(r=>r.id === newRouter.id ? newRouter : r);
    }
    
    setData({...data, checkedServersCount: getXCheckedServersCount(routers), routers: routers});
  }

  const deselectOrphans = () => {
    const routers = data.routers.map(r=> ({...r, servers: r.servers.map(s=>({...s, checked: Boolean(s.driver) ? s.checked : false}))}));
    
    setData({...data, checkedServersCount: getXCheckedServersCount(routers), routers: routers});
  }

  const runShortcut = async (server, commandId) => {
    if(commandId === 'liveLogs'){
      setLiveLogsServer(server);
    } else {
      const req = {
        commandId: commandId,
        serverId: server.id
      };
      let resp;
      try {
        resp = await ExecSshAsync(authKey, req);
        const serverClone = {
          ...server, 
          status: resp.isErrorOutput ? '🔴' : '🟢',
          response: resp
        };

        setFocusedServer(serverClone);
      } catch (error) {
        alert(error);
      } 
    }
  }

  const emojiCounts = data.routers.flatMap(r=>r.servers).reduce((acc, obj) => {
    if (acc[obj.status]) {
      acc[obj.status] += 1;
    } else {
      acc[obj.status] = 1;
    }
    return acc;
  }, {});

  const emojiCountsPerRouter = r => r.servers.reduce((acc, obj) => {
    if (acc[obj.status]) {
      acc[obj.status] += 1;
    } else {
      acc[obj.status] = 1;
    }
    return acc;
  }, {});

  const isRouterCollapsed = r => collapsedRouters.some(c => c === r.id);
  const toggleRouterCollapsation = r => {
    console.log(collapsedRouters);
    if(isRouterCollapsed(r)){
      console.log('collapsed',r);
      setCollapsedRouters(collapsedRouters.filter(c=>c!== r.id));
    } else {
      console.log('no collapsed',r);
      setCollapsedRouters([...collapsedRouters, r.id]);
    }
  };

  return <>

    <div style={{display: 'flex', alignItems: 'stretch', background: '#b9b9b9', padding:'5px'}}>
      <p style={{margin: '0', alignSelf: 'center'}}>Servers checked: {data.checkedServersCount} / {data.totalServersCount}
      <button type="button" className="btn btn-success btn-sm" onClick={() => setCheckedAll(undefined, true)} style={{padding:'0px 6px', margin: '0px 5px'}} disabled={isRunning}>All</button>
      <button type="button" disabled={isRunning} className="btn btn-danger btn-sm" onClick={() => setCheckedAll(undefined, false)} style={{padding:'0px 6px', marginRight: '5px'}}>None</button>
      <button type="button" disabled={isRunning} className="btn btn-danger btn-sm" onClick={() => deselectOrphans()} style={{padding:'0px 6px'}}>Orphans</button>
      </p>
      <div style={{flexGrow: '1'}}></div>
      <div style={{marginRight:'5px'}}>
        <select className="form-select" title='Parallel running count' disabled={isRunning} value={parallelCount} onChange={e=>setParallelCount(Number(e.target.value))}>
          { Array.from({ length: 20 }, (_, index) => index + 1).map(x=><option key={x} value={x}>{x}</option>)}
        </select>
      </div>
      <div>
        <select className="form-select" title='SSH Credentials' disabled={isRunning} value={selectedCommand} onChange={e=>setSelectedCommand(e.target.value === "none" ? null : e.target.value)}>
          <option value={undefined}>none</option>
          {data.commands.map(x=><option key={x.id} value={x.id}>{x.key}</option>)}
        </select>
      </div>
      <button className={`btn btn-primary`} style={{padding:'0px 6px', marginLeft:'5px'}} disabled={isRunning || !Boolean(selectedCommand)} onClick={async ()=> await go()}>GO</button>
    </div>
    <div className='emojiCount'>
      {
        Object.entries(emojiCounts).map(([status, count]) => (
          <label key={status}>{status} {count}</label>
        ))
      }
    </div>
    <div className='table-responsive'>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Aws Instance Id</th>
            <th>Ip</th>
            <th>Index</th>
            <th>Driver</th>
            <th>Status</th>
            <th>Shortcuts</th>
          </tr>
        </thead>
        <tbody>
          {data.routers.map(router => <Fragment key={router.id}><tr>
            <th colSpan={7} style={{background: '#b9b9b9'}}>
              <div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
                {router.name} 
                ({router.servers.filter(server => server.checked).length}/{router.servers.length}) 
                <button type="button" className="btn btn-success btn-sm" onClick={() => setCheckedAll(router, true)} style={{padding:'0px 6px', margin: '0px 6px'}} disabled={isRunning}>All</button> 
                <button type="button" disabled={isRunning} className="btn btn-danger btn-sm" onClick={() => setCheckedAll(router, false)} style={{padding:'0px 6px'}}>None</button>
                <button type="button" className="btn btn-warning btn-sm" onClick={() => toggleRouterCollapsation(router)} style={{padding:'0px 6px', marginLeft: '6px'}}>+/-</button>

                <div className='emojiCount'>
                  {
                    Object.entries(emojiCountsPerRouter(router)).map(([status, count]) => (
                      <label key={status}>{status} {count}</label>
                    ))
                  }
                </div>
              </div>
            </th>
          </tr>
          {!isRouterCollapsed(router) && router.servers.map(server => <tr key={server.id} title={server.id}>
            <td><input className="form-check-input" type="checkbox" disabled={isRunning} checked={server.checked} onChange={()=>toggleItem(server, router)}/></td>
            <th>{server.awsInstanceId}</th>
            <td>{server.ip}</td>
            <td>{server.index}</td>
            <td>{server.driver}</td>
            <td><div className={server.status === '🔵' ? 'blink_me' : ''} style={{cursor:'pointer'}} onClick={()=>setFocusedServer(server)}>{server.status}</div></td>
            <td>
              <button type="button" className="btn btn-secondary btn-sm mr5" onClick={async ()=>await runShortcut(server, '6637fc5ec8e4de9146c48c8e')} title='Top N lines of docker mitm logs'>↑</button>
              <button type="button" className="btn btn-secondary btn-sm mr5" onClick={async ()=>await runShortcut(server, '6637fca2c8e4de9146c48c8f')} title='Bottom N lines of docker mitm logs'>↓</button>
              <button type="button" className="btn btn-secondary btn-sm" onClick={async ()=>await runShortcut(server, 'liveLogs')} title='Live logs'>📺</button>
            </td>
          </tr>)}
          </Fragment>
          )} 
           
{/* 
          {servers.map((server, index) => <tr key={server.id} title={server.id}>
            <th>{index + 1}</th>
            <th>{server.awsInstanceId}</th>
            <td>{server.ip}</td>
            <td>{server.index ?? '-'}</td>
            <td>{getDriverName(server.driverId)}</td>
            <td>{getRouterName(server.routerId)}</td>
            <td><ServerStatus server={server}/></td>
            <td>{server.sshCredentials ?? '-'}</td>
            <td><Qr authKey={authKey} objectId={server.id} objectType='server' hasQr={true}/></td>
            <td style={{position:'relative'}}>
              <button type="button" className="btn btn-info btn-sm" onClick={()=>setFocusedServer(deepCopy(server))} style={{padding:'0px 6px'}}>Info</button>
              {Boolean(server.comment) && <label style={{position: 'absolute', left: '2px', top: '0'}} title={server.comment}>💬</label>} 
            </td>
          </tr>)} */}
        </tbody>
      </table>
    </div>
    
    {focusedServer && <>
      <div className="modal" tabIndex="-1" style={{display:'block', backgroundColor:'#0000003d'}} aria-hidden="true" role="dialog">
        <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Output {focusedServer.status}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setFocusedServer(null)}></button>
            </div>
            <div className="modal-body" style={{whiteSpace: 'pre'}} dangerouslySetInnerHTML={{__html: Anser.ansiToHtml(focusedServer.response?.output ?? '-')}}>
              {/* {focusedServer.response?.output ?? '-'} */}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setFocusedServer(null)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>}

    {liveLogsServer && <>
      <div className="modal" tabIndex="-1" style={{display:'block', backgroundColor:'#0000003d'}} aria-hidden="true" role="dialog">
        <div className="modal-dialog modal-dialog-scrollable modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Live logs: {liveLogsServer.ip} ({liveLogsServer.index})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setLiveLogsServer(null)}></button>
            </div>
            <div className="modal-body">
              <LiveLogs authKey={authKey} server={liveLogsServer} autoscroll={autoscroll}/>
            </div>
            <div className="modal-footer">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" id="autoscroll" checked={autoscroll} onChange={e=>setAutoscroll(e.target.checked)}/>
                <label className="form-check-label" htmlFor="autoscroll">
                  Autoscroll
                </label>
              </div>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setLiveLogsServer(null)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>}
  </>
}

//style={{whiteSpace: 'pre'}} dangerouslySetInnerHTML={{__html: Anser.ansiToHtml(liveLogsServer.response?.output ?? '-')}}
function LiveLogs({authKey, server, autoscroll}){
  // const [logs, setLogs] = useState('');
  const divRef = useRef(null);
  const [counter, setCounter] = useState(0);

  const scrollToBottom = () => {
    divRef.current.scrollIntoView(false);
  }

  useEffect(()=> {
    if(autoscroll){
      scrollToBottom();
    }
  }, [autoscroll, counter]);

  useEffect(()=>{
    if(authKey){
      const sse = new EventSource(getUrl(`/servers/livelogs?key=${authKey}&serverId=${server.id}`), { withCredentials: false });

      sse.onmessage = e => {
        const newDiv = document.createElement('div');
        newDiv.innerHTML = Anser.ansiToHtml(e.data);
        // console.log(d);
        divRef.current.appendChild(newDiv);

        setCounter(t => t + 1);
      }

      sse.onerror = () => {
        sse.close();
      }


      return () => {
        sse.close();
      };
    }
  }, [authKey]);

  // return <div ref={divRef} style={{whiteSpace: 'pre'}} dangerouslySetInnerHTML={{__html: Anser.ansiToHtml(logs)}}/>
  return <div ref={divRef} style={{whiteSpace: 'pre'}} />
}

function IptablesViewer({authKey, routers}){
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRouterId, setSelectedRouterId] = useState(null);

  const load = async () => {
    try {
      setIsLoading(true);
      setContent(null);
      const resp = await GetIptablesContentAsync(authKey, selectedRouterId);
      setContent(resp);
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return <>
    <div className='d-flex justify-content-end'>
      <div style={{flexGrow:'1'}}></div>
      <select style={{width:'150px'}} className="form-select mt10" title='Router' readOnly={isLoading} value={selectedRouterId} onChange={e=>setSelectedRouterId(e.target.value === 'none' ? undefined : e.target.value)}>
        <option value='none'>All</option>
        {routers.map(x=><option key={x.id} value={x.id}>{x.name}</option>)}
      </select>
      <button className='btn btn-primary btn-sm' style={{padding:'0px 6px', margin: '5px', marginTop:'15px', marginRight:'20px'}} disabled={isLoading} onClick={async ()=> await load()}>Load</button>
    </div>
    <div className="form-group" style={{padding:'20px', paddingTop:'10px'}}>
      {isLoading && <Loading/>}
      {content && <textarea className="form-control" rows="20" value={content} readOnly={true}></textarea>}
    </div>
  </>
}

function hasEmptyString(...args) {
  return args.some(arg => arg === null || arg === undefined || arg.trim() === '');
}

function AwsInstanceController({authKey, awsInstanceId, routerId, onlyStatus}){
  const hasAwsInstance = !hasEmptyString(awsInstanceId);
  const hasRouterId = !hasEmptyString(routerId);

  const [status, setStatus] = useState("Unknown");
  const [isMakingChange, setIsMakingChange] = useState(false);
    
  const onSelfClick = async () => {
    if(status === "Loading"){
      return;
    }

    setStatus("Loading");

    try {
      const resp = await GetAwsInstanceStatus(authKey, awsInstanceId, routerId);
      setStatus(resp.status);
    } catch(error){
      alert(error);
      setStatus("Failed");
    }
  };

  const startStop = async (startStop) => {
    
    if(isMakingChange)
      return;

    setIsMakingChange(true);

    try {
      await SetAwsInstanceStatus(authKey, awsInstanceId, routerId, startStop);
      await onSelfClick();
    } catch(error){
      alert(error);
    } finally {
      setIsMakingChange(false);
    }
  };

  return <div style={{cursor: 'pointer'}} onClick={() => onSelfClick()}>
    {!hasAwsInstance && "No AWS InstanceId" }
    {!hasRouterId && "No Router" }
    {hasAwsInstance && hasRouterId && <>
      {onlyStatus && status}
      {!onlyStatus && `AWS: ${status}`}
      {!onlyStatus && <>
        <button disabled={isMakingChange} style={{marginLeft:'5px', marginRight:'2px'}} onClick={e=>{e.stopPropagation(); startStop(true);}} title='Start AWS instance'>ჩ</button>
        <button disabled={isMakingChange} onClick={e=>{e.stopPropagation(); startStop(false);}} title='Stop AWS instance'>გ</button>
        </>} 
    </>}
    
  </div>
}

export default App;
